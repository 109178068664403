const KEYS = {
    Attributes: 'Attributes',
    AttributeId: 'AttributeId'
}

export const getObjectTypeCollection = () => ([
    { id: 'string', title: 'string' },
    { id: 'boolean', title: 'boolean' },
    { id: 'number', title: 'number' },
    { id: 'list', title: 'list' },
    { id: 'date', title: 'date' }
])

export function insertAttribute(data) {
    let Attributes = getAllAttributes();
    data['id'] = generateAttributeId();
    Attributes.push(data);
    localStorage.setItem(KEYS.Attributes, JSON.stringify(Attributes));
}

export function updateAttribute(data) {
    let Attributes = getAllAttributes();
    let recordIndex = Attributes.findIndex(x => x.id === data.id);
    Attributes[recordIndex] = { ...data };
    localStorage.setItem(KEYS.Attributes, JSON.stringify(Attributes));
}

export function generateAttributeId() {
    if (localStorage.getItem(KEYS.AttributeId) == null) {
        localStorage.setItem(KEYS.AttributeId, '0');
    }  
    var id = parseInt(localStorage.getItem(KEYS.AttributeId));
    localStorage.setItem(KEYS.AttributeId, (++id).toString());
    return id;
}

export function getAllAttributes() {
    if (localStorage.getItem(KEYS.Attributes) == null) {
        localStorage.setItem(KEYS.Attributes, JSON.stringify([]));
    }
    let Attributes = JSON.parse(localStorage.getItem(KEYS.Attributes));
    //map departmentID to department title
    let departments = getObjectTypeCollection();
    return Attributes.map(x => ({
        ...x,
        department: departments[x.departmentId - 1].title
    }));
}