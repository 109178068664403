import * as React from 'react';
import {
  SpinnerContainer,
  Spinner,
  SpinnerText,
  SpinnerWithText,
} from '../../services/common/loading-style';


export default function AccessDenied() {
  const  loadingText ='You do not have access to this page.';

 

  return (
    <SpinnerContainer>
    <SpinnerWithText>
        <Spinner data-testid="spinner" />
        <SpinnerText>{loadingText}</SpinnerText>
    </SpinnerWithText>
</SpinnerContainer>
  );
}