import * as React from 'react';
import {
  SpinnerContainer,
  Spinner,
  SpinnerText,
  SpinnerWithText,
} from '../../services/common/loading-style';
export default function LoadingPage() {
  const  loadingText ='Processing...';

 

  return (
    <SpinnerContainer>
    <SpinnerWithText>
        <Spinner data-testid="spinner" />
        <SpinnerText>{loadingText}</SpinnerText>
    </SpinnerWithText>
</SpinnerContainer>
  );
}