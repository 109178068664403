import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export default function CustomDatePicker(props) {

    const { name, label, value, onChange } = props;
    

    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
         name={name}
            autoOk
            variant="inline"
            inputVariant="outlined"
            label={label}
            format="MM/dd/yyyy"
            value={value}
            InputAdornmentProps={{ position: "start" }}
            onChange={date => onChange(convertToDefEventPara(name, date))}
        />
  </LocalizationProvider>
    )
}
