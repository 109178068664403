import React, { useState, useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import Layout from "./adminlayout/layout";

import { PingIdpAuth } from "./components/auth/ping-auth.component";
import ServerError from "./components/error-handling/serverError.component";
import AccessDenied from "./components/error-handling/accessDenied.component";
import ConfigurationObjects from "./components/configurationObjects.component";
import AuditManagement from "./components/auditManagement.component";
import AgentContingency from "./components/agent-contingency";

/*
import { FormContext } from './components/customForms/FormContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
*/

export default function App() {
  return (
    <Routes>
      <Route element={<PingIdpAuth />}>
        <Route path="/" element={<Layout />}>
          <Route path="/objectConfig" element={<ConfigurationObjects />} />
          <Route path="/objectConfig/*" element={<ConfigurationObjects />} />
          <Route path="/audit" element={<AuditManagement />} />
          <Route path="/contingency" element={<AgentContingency />} />
        </Route>

        <Route path="/denied" element={<AccessDenied />} />
        <Route path="/error" element={<ServerError />} />
      </Route>
    </Routes>
  );
}
