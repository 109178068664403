import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

export const customTheme=createTheme(
    {
      palette:{
        primary:{
          main:'#2E3B55'
        },
        secondary:orange
      },
      typography: {
        fontFamily: 'Quicksand',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
      }
    }
  );