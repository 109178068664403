
import * as request from 'superagent';
import authClient from './access-api';
import config from './config';
import jwt_decode from 'jwt-decode';
const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY';
const EXPIRY_TIME_KEY = 'EXPIRY_TIME';
const STATE_KEY = 'STATE_KEY';
const ID_TOKEN = 'ID_TOKEN';
const NONCE_KEY = 'NONCE_KEY';



export function getStoredAccessToken() {
  sessionStorage.getItem(ACCESS_TOKEN_KEY);
}


export function setStoredAccessToken(token) {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
}


export function removeStoredAccessToken()
{ 
      sessionStorage.removeItem(ACCESS_TOKEN_KEY);
}

export function getStoredExpiryTime()
{
        sessionStorage.getItem(EXPIRY_TIME_KEY);
}

export function setStoredExpiryTime(expiryTime)  {
    const unixTimeNow = new Date().getTime();
    const expiryTimeUpdate = unixTimeNow + Number(expiryTime) * 1000;
    sessionStorage.setItem(EXPIRY_TIME_KEY, String(expiryTimeUpdate));
}

export function removeStoredExpiryTime()
{
        sessionStorage.removeItem(EXPIRY_TIME_KEY);
}

export function getStateKey(){
     sessionStorage.getItem(STATE_KEY);
}
export function setStateKey(stateToken) {
    sessionStorage.setItem(STATE_KEY, stateToken);
}
export function removeStateKey(){
     sessionStorage.removeItem(STATE_KEY);
}
export function getIdKey() {
    sessionStorage.getItem(ID_TOKEN);
}
export function setIdKey(idToken){
    sessionStorage.setItem(ID_TOKEN, idToken);
}
export function removeIdKey(){
     sessionStorage.removeItem(ID_TOKEN);
}

export function getNonceKey(){
     sessionStorage.getItem(NONCE_KEY);
}
export function setNonceKey(nonceToken){
    sessionStorage.setItem(NONCE_KEY, nonceToken);
}
export function removeNonceKey() 
{
    sessionStorage.removeItem(NONCE_KEY);
}

export function clearSessionKeys() {
    sessionStorage.removeItem(ID_TOKEN);
    sessionStorage.removeItem(STATE_KEY);
    sessionStorage.removeItem(EXPIRY_TIME_KEY);
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
    sessionStorage.removeItem(NONCE_KEY);
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    sessionStorage.removeItem('isLoggedIn')
};



/**
 *
 * @returns random generated string to be used for auth requests
 */
export function generateRandomValue() {
    return Math.random().toString(36).substring(2, 12);
};


export function getUserDetailsFromToken(key,token){
    var decoded = jwt_decode(token);
    let returnValue = decoded[key]
    // check is added only because the ping returns extra 2 0's
    if (key === 'emp id') {
        if (returnValue.slice(0, 2) === '00') {
            returnValue = returnValue.slice(2);
        } else if (returnValue.charAt(0) === '0') {
            returnValue = returnValue.slice(1);
        }
    }
    return returnValue;
};


export function getUserDetails(key,UserData) {
   //
};

