import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import Controls from "./controls/Controls";
import { Typography } from "@material-ui/core";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  Alert,
  Skeleton,
} from "@mui/material";

const AgentContingency = () => {
  const [data, setData] = useState(null);
  const [selectedBU, setSelectedBU] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const fetchBUsAndLocations = async () => {
    const { data } = await axios.get(process.env.REACT_APP_BUSANDLOCATION_API, {
      headers: { Authorization: sessionStorage.getItem("ACCESS_TOKEN") },
    });

    console.log("BUs&Locations", data);
    setData(data.body);
  };

  useEffect(() => {
    fetchBUsAndLocations();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOGOUTAGENTS_API,
        {
          BU: selectedBU,
          AgentAssignedLocation: selectedLocation,
        },
        {
          headers: { Authorization: sessionStorage.getItem("ACCESS_TOKEN") },
        }
      );

      console.log("API Response:", response.data);
      setMessage("Success setting Agents' status to invalid.");
      setSeverity("success");
      setSelectedBU(null);
      setSelectedLocation(null);
      fetchBUsAndLocations();
    } catch (error) {
      // Handle errors
      console.error("API Error:", error.message);
      setMessage("Error setting Agents' status to invalid.");
      setSeverity("error");
    }
  };

  const bUs = useMemo(() => {
    // Ensures that bUs is only recalculated when data changes.
    return data ? Object.keys(data) : [];
  }, [data]);

  const locations = selectedBU ? Object.keys(data[selectedBU]) : [];
  const handleBUChange = (value) => {
    setSelectedBU(value);
    setSelectedLocation("");
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };
  return (
    <div
      style={{
        width: "500px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {!data ? (
        <Skeleton variant="rectangular" width={500} height={40} />
      ) : (
        <Autocomplete
          value={selectedBU}
          onChange={(event, newValue) => handleBUChange(newValue)}
          options={bUs}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField {...params} label="Select BU" variant="outlined" />
          )}
        />
      )}

      <FormControl style={{ width: "100%" }}>
        <InputLabel>Select Location</InputLabel>
        <Select
          disabled={!selectedBU}
          value={selectedLocation}
          onChange={handleLocationChange}
          label="Select Location"
        >
          <MenuItem value="" disabled>
            Select Location
          </MenuItem>
          {locations.map((location) => (
            <MenuItem key={location} value={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div>
        {selectedBU && selectedLocation && (
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ color: "#ed6c02" }}
          >
            This action will set {data[selectedBU][selectedLocation]} agents
            status to invalid
          </Typography>
        )}
        <Controls.Button
          disabled={!selectedBU || !selectedLocation}
          onClick={handleSubmit}
          text="Submit"
        />
      </div>
      {severity && message && (
        <Alert onClose={() => setMessage("")} severity={severity}>
          {message}
        </Alert>
      )}
    </div>
  );
};

export default AgentContingency;
