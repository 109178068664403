import * as React from 'react';
import { customTheme } from './themes/custom-theme';
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PingIdpAuth } from './components/auth/ping-auth.component'
import { createTheme, ThemeProvider } from '@mui/material/styles';

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);




root.render(
  <StrictMode>
    <BrowserRouter>
      
        <ThemeProvider theme={customTheme}>
          <App />
        </ThemeProvider>
      
    </BrowserRouter>
  </StrictMode>
);
