import React from 'react';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function TimePicker(props) {

    const { name, label, value, onChange } = props;

    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeField
            autoOk
            variant="inline"
            inputVariant="outlined"
            label={label}
            name={name}
            format="HH:mm"
            value={value}
            InputAdornmentProps={{ position: "start" }}
            onChange={time => onChange(convertToDefEventPara(name, time))}
        />
          </LocalizationProvider>
    )
}
